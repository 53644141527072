import React from "react";
import ProdLayout from "../../prodlayout";
import pi1 from "./p1.jpg";
import pi2 from "./p2.jpg";
import pi3 from "./p3.jpg";
import pi4 from "./p4.jpg";
const data = {
  images: [pi1, pi2, pi3, pi4],
  badge: "Printed",
  sku: "SHK07",
  availability: "In stock",
  title: "OCSO Solid Men's shorts SHK07",
  mrpPrice: "1190",
  sellPrice: "549",
  disc:
    "Grey in shading, these shorts from ocso are well-suited for a wide range of exercise centered training like treadmill, jaw ups, bouncing jacks, and so on. Enlivened with heathered impact and difference side boards on the sides, these shorts are itemized with intelligent logo print. Formed in normal fit from dampness wicking polyester for a delicate vibe, these shorts will work out in a good way for a T-shirt and running shoes.",
};
export default () => {
  return <ProdLayout data={data} />;
};
